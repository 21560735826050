import './input-box.styles.scss';

const InputBox = (props) => {
    const { LabelSymbol, type, name, placeholder, labelText, withoutLabel, onChange, value, className } = props;
    return (
        <div className={`input-group ${className}`}>
            {withoutLabel ? null : <label for={name} className="input-group-text">{LabelSymbol ? <LabelSymbol /> : labelText}</label>}
            <input type={type} id={name} className="form-control" placeholder={placeholder} onChange={onChange} value={value} />
        </div>
    );
}

export default InputBox;