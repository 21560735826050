import Carousel from "../../component/carousel/carousel.component";
import { ReactComponent as Star } from '../../assets/star-fill.svg';

import './aboutus.styles.scss';

const AboutUs = () => {
    const pointsContent = ['Competitive Product Prices', 'Superior Quality Products', 'Client-Centric Approach', 'Experienced Professionals', 'Effective Quality Control', 'Reliable Delivery Services']

    const points = []
    for (let i = 0; i < pointsContent.length; i++) {
        points.push(
            <p className='d-flex align-items-center m-0 my-2 mx-2'>
                <span><Star fill='#339933' /></span>
                <span className='ms-2 point-info'>{pointsContent[i]}</span>
            </p>
        );
    }


    return (
        <div className="row m-0 mx-2 mx-md-4">
            <div className="col-12 col-md-6 col-lg-7 ">
                <div className="intro-aboutus mt-3">
                    <p className='intro-aboutus-head m-0'>Welcome to V.E.C.</p>
                    <p className="intro-aboutus-para m-0 mt-2">
                        We take immense pride in introducing ourselves as a prominent supplier of industrial valves, specializing in a wide range of ferrous and non-ferrous metals.
                        Our distinguished reputation has been meticulously crafted over the years through an unwavering commitment to ensuring customer satisfaction.
                        At the core of our dedication lies the promise to consistently deliver the highest quality products to our valued clients, all while maintaining competitive prices.
                        Here, we provide a comprehensive overview of our company, aimed at providing you with a crystal-clear understanding of our capabilities as trusted suppliers.
                    </p>
                </div>
                <div className="vis-mis mt-3">
                    <p className="vis-mis-head m-0 ">Vision & Mission</p>
                    <div className="vis-mis-para mt-3 d-flex">
                        <p className="vis-para m-0 me-3 ms-2 p-2 px-3">
                            Our vision is to gain worldwide recognition for our excellence in valve markets.
                            We strive for engineering brilliance, outstanding customer service, and top-performing products.
                        </p>
                        <p className="mis-para m-0 ms-3 p-2 me-2 px-3">
                            Our mission is all about working together to empower global success.
                            Through our expertise and dedication, we help clients surpass their business objectives.
                        </p>
                    </div>
                </div>
                <div className="strength-para d-none d-lg-block mt-3">
                    <p className="strength-para-head m-0"> Our Strengths</p>
                    <div className="points d-flex flex-wrap justify-content-around">
                        {points}
                    </div>
                </div>
            </div>
            <div className='row m-0 col-12 col-md-6 col-lg-5 mt-3 d-flex flex-md-column align-self-start align-self-lg-center'>
                <div className="col-12 col-sm-6 col-md-12 order-2 order-md-1">
                    <p className='carousel-head'>Our Products</p>
                    <Carousel />
                </div>

                <div className=" col-12 col-sm-6 col-md-12 strength-para mt-1 mt-md-3 d-lg-none order-1 order-md-2">
                    <p className="strength-para-head m-0"> Our Strengths</p>
                    <div className="points d-flex flex-wrap justify-content-around">
                        {points}
                    </div>
                </div>
            </div>
        </div>

    );
}


export default AboutUs;