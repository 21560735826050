import './spinner.styles.scss';

const Spinner = (props) => {
    var { className } = props;
    className += ' spinner-border'
    return (
        <div className={className} role="status" >
            <span className="visually-hidden">Loading...</span>
        </div>
    );
}

export default Spinner;