import { Link } from 'react-router-dom';
import './button.styles.scss';
import Spinner from '../spinner/spinner.component';
const Button = (props) => {
    var { text, className, to, onClick, type, isLoading } = props;
    if (!className) className = '';
    if (!to) to = '';

    if (to) {
        // If a "to" prop is provided, render a navigation link
        return (
            <Link to={to} className="d-flex justify-content-center">
                <button type="button" className={"btn px-4 " + className}>{text}</button>
            </Link>
        );
    } else {
        // If no "to" prop is provided, render a regular button
        return (
            <div className="d-flex justify-content-center">
                <button type={type} className={"btn px-4 " + className} onClick={onClick}>
                    {isLoading ? <Spinner className='button-spinner' /> : text}

                </button>

            </div>
        );
    }
}
export default Button;