import './home.styles.scss';
import Button from '../../component/button/button.component';
import { ReactComponent as Point } from '../../assets/asterisk.svg';
import MainCarousel from '../../component/main-carousel/main-carousel.component';
import { ReactComponent as Redirect } from '../../assets/box-arrow-up-right.svg';
import { Link } from 'react-router-dom';

const Home = () => {
    const products = []

    const productsContent = ['Ball valves flange end – ASA 150 # – 15 NB to 300 NB.', 'Ball valves flange end – ASA 300 # – 15 NB to 200 NB.',
        'Butterfly valves – 40 NB to 300 NB.', 'Full view sight glass – 15 NB to 200 NB.', 'Double window sight glass – 25 NB to 150 NB.',
        'Flush bottom valves – 25 NB to 150 NB.', 'Disc type check valves – 15 NB to 200 NB.', 'Strainers (Pot type and y type) – 15 NB to 300 NB.']

    for (let i = 0; i < productsContent.length; i++) {
        products.push(
            <p className='m-0 d-flex align-items-center my-1'>
                <span><Point fill='#339933' /></span>
                <span className='products-info ms-2'>{productsContent[i]}</span>
            </p>
        );
    }

    return (
        <div className='row m-0 mx-4'>
            <div className='row m-0 p-0'>
                <div className='col p-0 d-flex flex-column flex-sm-row align-items-center justify-content-center mt-2 intro-line'>
                    <p className='m-0'>Authorized Distributers for </p> <p className='m-0 mx-1' style={{ color: 'red' }}>ADDCON</p> <p className='m-0'>Make Valves for Andhra / Telangana Zone</p>
                </div>

                <div className='col mt-3 main-carousel'>
                    <MainCarousel />
                </div>
                <div className='col p-0 d-flex justify-content-center flex-sm-row m-1 mt-2 intro-line'>
                    <p className='m-0'>We Provide Finest</p><p className='mx-1 m-0' style={{ color: 'red' }}>Quality Standard Valves</p>
                </div>
            </div>

            <div className='row m-0 p-0 text-content'>
                <div className='col-12 col-sm-10 mx-auto col-md-6 col-lg-7 d-flex flex-wrap m-2 justify-content-lg-end justify-content-center'>
                    <p className='p-2 my-1 info para1'>
                        Welcome to Venus Engineering Corporation, established in 2011. As Addcon's accredited vendors, we take pride in offering top-quality ball valves to clients across South India, with a strong presence in Telangana and Andhra Pradesh.
                    </p>
                    <p className='p-2  my-1 info'>
                        Our expertise in a wide range of valves, including ball valves, butterfly valves, gate valves, and globe valves in CS and SS variants, ensures that we deliver products adhering to the highest quality standards.
                    </p>
                    <p className='p-2 my-1 info para3'>
                        Venus Engineering is committed to excellence and affordability. We are a preferred choice in the industry. We offer precise, reliable, and valuable solutions for our clients.</p>
                    <Link to='aboutus' className='aboutuslink d-flex me-2 mt-2'>
                        <p>...More About Us </p>
                        <Redirect className='m-1' />
                    </Link >
                </div>
                <div className='col-12 col-sm-10 col-md mx-auto'>
                    <div className='products-range d-flex flex-wrap justify-content-around'>
                        <p className='products-range-head m-0'>Products-Range</p>
                        <div className='products-range-para d-flex flex-column mt-1'>
                            {products}
                        </div>
                    </div>
                    <Button text='Get In Touch' className='mt-3 mt-xl-2' to='/contact' />
                </div>
            </div>
        </div>
    );
}

export default Home;