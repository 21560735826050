import image1 from '../../assets/valve/Butterfly-Valve (1).jpg';
import image2 from '../../assets/valve/Globe-Valve (1).jpg';
import image3 from '../../assets/valve/InkedBall-Valve (1).jpg';
import image4 from '../../assets/valve/InkedGate-Valve (1).jpg';
import image5 from '../../assets/valve/Plug-Valve1.jpg';

import { ReactComponent as Next } from '../../assets/arrow-right-circle-fill.svg';
import { ReactComponent as Prev } from '../../assets/arrow-left-circle-fill.svg';


import './carousel.styles.scss';

const Carousel = () => {

    return (
        <div id="carouselExample" className="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={image1} className="img-fluid" alt="..."></img>
                </div>
                <div className="carousel-item">
                    <img src={image2} className="img-fluid" alt="..."></img>
                </div>
                <div className="carousel-item">
                    <img src={image3} className="img-fluid" alt="..."></img>
                </div>
                <div className="carousel-item">
                    <img src={image4} className="img-fluid" alt="..."></img>
                </div>
                <div className="carousel-item">
                    <img src={image5} className="img-fluid" alt="..."></img>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <Prev className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <Next className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Carousel;