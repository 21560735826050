import { ReactComponent as Email } from "../../assets/envelope-at-fill.svg";
import { ReactComponent as Name } from "../../assets/person-circle.svg";
import { ReactComponent as Phone } from "../../assets/telephone-fill.svg";
import { ReactComponent as Message } from "../../assets/chat-square-text-fill.svg";
import { ReactComponent as Add } from '../../assets/plus-circle-fill.svg';
import { ReactComponent as Subtract } from '../../assets/dash-circle-fill.svg';
import { useState, useEffect, useContext } from "react";
import { ToastContext } from "../../context/toast.context";
import axios from "axios";
import InputBox from "../../component/Input-box/input-box.component";
import Textarea from "../../component/textarea/textarea.component.jsx";
import Button from "../../component/button/button.component";
import Quantity from "../../component/quantity/quantity.component";
import Alert from "../../component/alert/alert.component";
import './getquote.styles.scss';

const defaultFormFields = {
    name: '',
    phone: '',
    email: '',
    message: ''
}

const focus = { name: false, phone: false, email: false }

const GetQuote = () => {

    const { addToastToArray } = useContext(ToastContext);

    const [formFields, setFormFields] = useState(defaultFormFields)
    const [nameAlert, setNameAlert] = useState('');
    const [emailAlert, setEmailAlert] = useState('');
    const [phoneAlert, setPhoneAlert] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { name, email, phone, message } = formFields;

    const item = { name: "", quantity: 1, id: Date.now(), description: '', unit: 'units' };
    const [items, setItems] = useState([item]);
    const validateName = () => {
        const validName = (name) => {
            const regex = /^[A-Za-z\s]+$/;
            return regex.test(name);
        }

        if (name.length < 1 && focus.name) setNameAlert('Please Enter Your Name')
        else if (!validName(name) && focus.name) setNameAlert('Please Enter Valid Name')
        else setNameAlert('');
    }

    const validateEmail = () => {
        const validEmail = (email) => {
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return regex.test(email);
        }

        if (email.length < 1 && focus.email) setEmailAlert('Please Enter Email')
        else if (!validEmail(email) && focus.email) setEmailAlert('Please Enter Valid Email')
        else setEmailAlert('');
    }

    const validatePhone = () => {

        const validPhone = (phone) => {
            const numericRegex = /^[0-9]+$/;
            return numericRegex.test(phone);
        }

        if (phone.length < 1 && focus.phone) setPhoneAlert('Please Enter Phone Number')
        else if (!validPhone(phone) && focus.phone) setPhoneAlert('Please Enter Valid Number')
        else if (phone.length === 11 && phone[0] === '0') setPhoneAlert('')
        else if (phone.length !== 10 && focus.phone) setPhoneAlert('Enter 10 digit Number')
        else setPhoneAlert('')
    }

    useEffect(validatePhone, [phone])
    useEffect(validateEmail, [email])
    useEffect(validateName, [name]);


    const handleChange = (e) => {
        const { id, value } = e.target;
        focus[id] = true;
        setFormFields({ ...formFields, [id]: value });
    }

    const checkOnFocus = () => {
        for (const id in focus) {
            if (focus[id] === false) {
                return true;
            }
        }
        return false;
    }

    const turnOnFocus = () => {
        for (const id in focus) focus[id] = true;
        validateEmail();
        validatePhone();
        validateName();
    }

    const resetForm = () => {
        setFormFields(defaultFormFields);
        setItems([item]);
        for (const id in focus) focus[id] = false;
        setEmailAlert('');
        setPhoneAlert('');
        setNameAlert('');
    }

    const sendQuote = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (phoneAlert.length > 0 || nameAlert.length > 0 || emailAlert.length > 0 || checkOnFocus()) {
            turnOnFocus();
        }
        else {
            const quoteContent = {
                name: name,
                email: email,
                phone: phone,
                items: items,
                message: message
            }

            try {
                const response = await axios.post('/.netlify/functions/send-quote', quoteContent)

                const { quoteError } = response.data;
                if (quoteError === false) {
                    resetForm();
                    addToastToArray({ type: 'success', text: 'Quote sent successfully' })
                }
                else {
                    const { error } = response.data;
                    console.error('error sending quote in serverless function', error)
                    addToastToArray({ type: 'danger', text: 'Failed to send quote' })
                }

            }
            catch (error) {
                console.error('quote sending error in quote component', error);
                addToastToArray({ type: 'danger', text: 'Failed to send quote' })
            }
        }
        setIsLoading(false);
        return;

    }

    const handleAddItem = () => {
        setItems([...items, item]);
    };

    const handleSubtractItem = (index) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('ho');
    }

    const handleItemNameChange = (event, index) => {
        const updatedItems = [...items];
        updatedItems[index].name = event.target.value;
        setItems(updatedItems);
    };

    const handleItemQuantityChange = (event, index) => {
        const updatedItems = [...items];
        updatedItems[index].quantity = event.target.value;
        setItems(updatedItems);
    };

    const handleQuantityUnitChange = (event, index) => {
        const updatedItems = [...items];
        updatedItems[index].unit = event.target.value;
        setItems(updatedItems)
    }

    const handleItemDescChange = (event, index) => {
        const updatedItems = [...items];
        updatedItems[index].description = event.target.value;
        setItems(updatedItems)
    }

    return (
        <div className="row mt-4 m-0 justify-content-center">
            <div className="col-10 col-sm-6 col-md-5 col-lg-4 p-0" >
                <form onSubmit={handleSubmit}>
                    <p className="text-center cdh mb-2">Send Inquiry</p>
                    <InputBox LabelSymbol={Name} type='text' name='name' placeholder="Enter Your Name" className='mb-3' onChange={handleChange} value={name} />
                    {nameAlert ? (<Alert alertMessage={nameAlert} />) : ('')}
                    <InputBox LabelSymbol={Email} type='email' name='email' placeholder="Enter Your Email" className='mb-3' onChange={handleChange} value={email} />
                    {emailAlert ? (<Alert alertMessage={emailAlert} />) : ('')}
                    <InputBox LabelSymbol={Phone} type='tel' name='phone' placeholder="Enter Your Phone" className='mb-3' onChange={handleChange} value={phone} />
                    {phoneAlert ? (<Alert alertMessage={phoneAlert} />) : ('')}


                    <div className='row m-0 item'>
                        <div className='row m-0 p-0'>
                            <p className="col-9 p-0 m-0 mb-1">Enter Item Name</p>
                            <p className="col-3 p-0 text-center m-0 mb-1">Qty</p>
                        </div>
                        {items.map((item, index) => (
                            <div key={item.id} className='row m-0 p-0 justify-content-end'>
                                <div className='col p-0 pe-1'>
                                    <InputBox labelText={index + 1} type='text' name={`itemName${index}`}
                                        placeholder="Enter Item Name" value={item.name} className='mb-2'
                                        onChange={(e) => handleItemNameChange(e, index)}
                                    />
                                </div>

                                <div className="col-auto p-0 ps-1">
                                    <Quantity onInputChange={(e) => handleItemQuantityChange(e, index)} onUnitChange={(e) => handleQuantityUnitChange(e, index)} />
                                </div>

                                <div className='col-12 p-0 item-description'>
                                    <Textarea name={`itemDescription${index}`} placeholder='Item Description' className='item-description mb-4' onChange={(e) => handleItemDescChange(e, index)} />
                                </div>
                                {index > 0 && (
                                    <div className='col-1 subbtn p-0'>
                                        <Subtract onClick={() => handleSubtractItem(index)} />
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="row m-0 justify-content-center">
                            <Add className='addbtn col-auto' onClick={handleAddItem} />

                        </div>
                    </div>

                    <Textarea name='message' LabelSymbol={Message} className='mb-3' onChange={handleChange} value={message} />
                    <Button type='submit' text='Submit' className onClick={sendQuote} isLoading={isLoading} />
                </form>

            </div>
        </div>
    );
}

export default GetQuote;