import { useState } from 'react'
import './map.styles.scss'
import Spinner from '../spinner/spinner.component';

const Map = () => {
    const [isMapLoaded, setMapLoad] = useState(false);
    const handleMapLoad = () => {
        setMapLoad(true);
    }
    return (
        <div className='d-flex justify-content-center align-items-center'>
            {isMapLoaded ? null : (
                <div className='spinner-container'>
                    <Spinner className='spinner' />
                </div>
            )}
            <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.574317633919!2d78.48741570989277!3d17.432204701445162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b6a7bd53955%3A0x9c4e124603141d7e!2sVenus%20Engineering%20Corporation!5e0!3m2!1sen!2sin!4v1689850227552!5m2!1sen!2sin"
                className='map'
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                onLoad={handleMapLoad}
            ></iframe>

        </div>
    );
}

export default Map;