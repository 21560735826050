import './navlink.styles.scss';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Navlink = (props) => {
    const location = useLocation();
    const link = location.pathname;
    const { to, name, colorClass, onClick } = props;
    return (
        <Link className={`navlink ${colorClass} ${link === to ? 'active' : ''}`} onClick={onClick} to={to}>{name}</Link>
    );
}

export default Navlink;