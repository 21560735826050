import { createContext, useState } from "react";


const addToast = (toastToAdd, toastArray) => {
    const toastList = [...toastArray];
    toastList.push({ ...toastToAdd, id: new Date().getTime() });
    return toastList;
}

const removeToast = (toastToRemove, toastArray) => {
    const toastList = [...toastArray];
    return toastList.filter((toast) => toast.id !== toastToRemove)
}

export const ToastContext = createContext({
    toastArray: [{}],
    setToastContent: () => null,
})

export const ToastProvider = ({ children }) => {

    const [toastArray, setToastArray] = useState([]);

    const addToastToArray = (toastToAdd) => {
        const toastList = addToast(toastToAdd, toastArray);
        setToastArray(toastList);
    }

    const removeToastFromArray = (toastToRemove) => {
        const toastList = removeToast(toastToRemove, toastArray);
        setToastArray(toastList);
    }

    const value = { addToastToArray, toastArray, removeToastFromArray };
    return (<ToastContext.Provider value={value}>{children}</ToastContext.Provider>);
}