import { Route, Routes } from "react-router-dom";
import Navigation from "./routes/navigation/navigation.component.jsx";
import Contact from "./routes/contact/contact.component.jsx";

import Home from "./routes/home/home.component.jsx";
import AboutUs from "./routes/aboutus/aboutus.component.jsx";
import GetQuote from './routes/getquote/getquote.component.jsx';



const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/getquote' element={<GetQuote />} />
      </Route>
    </Routes>
  );

}

export default App;
