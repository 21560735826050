import { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';
import './navigation.styles.scss';
import { ReactComponent as Logo } from '../../assets/New Project.svg';
import { ReactComponent as Menu } from '../../assets/icons8-menu (2).svg';
import { ReactComponent as Cross } from '../../assets/icons8-multiply.svg'
import { ReactComponent as CopyRight } from '../../assets/c-circle.svg'
import { ReactComponent as Email } from '../../assets/envelope-at-fill - Copy.svg'
import ContactLink from '../../component/contact-link/contact-link.component';
import Navlink from '../../component/navlink/navlink.component';
import Button from '../../component/button/button.component';
import Toast from '../../component/toast/toast.component';



const Navigation = () => {

    const { pathname } = useLocation();
    useEffect(() => { window.scrollTo(0, 0) }, [pathname])
    const [visible, setVisible] = useState(true);
    const navlinks = () => {
        setVisible(!visible);
    }

    return (
        <Fragment>
            <nav >
                <div className='row m-0 d-flex align-items-center justify-content-between px-2'>
                    <Link className='col-auto p-0 logo' to='/'><Logo fill='#339933' /></Link>
                    <Link className='col-auto p-0 ms-2 mb-1 logo-text'>
                        Venus Engineering Corporation
                    </Link>
                    <div className='links-container col p-0 d-flex justify-content-evenly'>
                        <Navlink colorClass='navlink-green' to="/" name="Home" />
                        <Navlink colorClass='navlink-green' to="/aboutus" name="About Us" />
                        <Navlink colorClass='navlink-green' to='/contact' name='Contact Us' />
                    </div>
                    <div className='col-auto p-0 d-flex align-items-center'>
                        {visible ? <Button text='Get Quote' className='nav-btn' to='/getquote' /> : ('')}
                        <Link onClick={navlinks} className={` ms-2 icon ${visible ? 'clicked' : ''}`}>
                            {visible ? <Menu /> : <Cross />}
                        </Link>
                    </div>

                </div>
                <div className={`d-flex flex-column align-items-center iconlinks ${!visible ? 'appear' : 'disappear'} `}>
                    <Navlink onClick={navlinks} colorClass='navlink-white' to="/" name="Home" />
                    <Navlink onClick={navlinks} colorClass='navlink-white' to="/aboutus" name="About Us" />
                    <Navlink onClick={navlinks} colorClass='navlink-white' to="/contact" name="Contact Us" />
                    <Navlink onClick={navlinks} colorClass='navlink-white' to="/getquote" name="Get Quote" />
                </div>
                {visible ? <div className="line mx-2 mt-2"></div> : ('')}
            </nav>
            <Outlet />
            <Toast />
            <footer className='row m-0 p-0'>
                <div className='col-12 col-sm d-flex align-items-center justify-content-center mb-1 mb-sm-0 mt-1 mt-sm-0'>
                    <CopyRight style={{ flexShrink: 0 }} /><span className='ms-2'>VEC 2023. All Rights Reserved.</span>
                </div>
                <div className='col-12 col-sm d-flex flex-column align-items-center align-items-sm-end justify-content-center'>
                    <p className='m-0 mb-1 mb-sm-0'>Designed By: Hatim Daidy</p>
                    <ContactLink type='mailto' content='htimdady31i@gmail.com' LabelSymbol={Email} />
                </div>
            </footer>
        </Fragment>
    );
}

export default Navigation;