import { ToastContext } from "../../context/toast.context";
import { useContext, useEffect, useState } from "react";
import { ReactComponent as Logo } from '../../assets/New Project.svg';
import { ReactComponent as Success } from '../../assets/check-circle-fill.svg';
import { ReactComponent as Danger } from '../../assets/exclamation-octagon-fill.svg';
import './toast.styles.scss';

const Toast = () => {

    const { toastArray, removeToastFromArray } = useContext(ToastContext);


    const removeToast = (toast) => {
        removeToastFromArray(toast.id);
    }

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const getTimeDifference = (currentTime, timestamp) => {
        const timeDiff = Math.abs(currentTime - timestamp);
        const minutes = Math.floor(timeDiff / 60000);
        return `${minutes} mins ago`;
    };

    const toastList = toastArray.map((toast, index) => {

        const className = `toast show ${toast.type}`;
        return (
            <div key={toast.id} className={className} role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true" data-bs-delay='2000' data-bs-animation="true">
                <div className="toast-header">
                    <Logo className='rounded me-2' fill='#339933' style={{ width: '20px', height: '20px' }} />
                    <strong className="me-auto">VEC</strong>
                    <small className="text-body-secondary">{getTimeDifference(currentTime, toast.id)}</small>
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => { removeToast(toast) }}></button>
                </div>
                <div className="toast-body d-flex ">
                    <p className="m-0 me-2 ">{toast.type === 'success' ? <Success /> : <Danger />}</p>
                    <p className="m-0 toast-body-text">{toast.text}</p>
                </div>
            </div>
        )
    });

    return (
        <div className="toast-container ">
            {toastList}
        </div>

    );
}

export default Toast;