import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";

import image1 from '../../assets/VAKLVE/Flush-Bottom-Ball-Valve.png';
import image2 from '../../assets/VAKLVE/Y-type-Strainer_4.png';
import image3 from '../../assets/VAKLVE/Three-Piece-Ball-Valve_2.png';
import image4 from '../../assets/VAKLVE/Two-Piece-Ball-Valve.png';

import image5 from '../../assets/VAKLVE/Y-type-Strainer_3.png';
import image6 from '../../assets/VAKLVE/Single-Piece-Ball-Valve.png';
import image7 from '../../assets/VAKLVE/Butterfly-Valve_F-edited.png';
import image8 from '../../assets/VAKLVE/Single-Piece-Ball-Valve-Screwed_2.png';
import image9 from '../../assets/VAKLVE/Lug-buttterfly-valve-edited.png';
import image10 from '../../assets/VAKLVE/Two-Piece-Ball-VAlve-GOg.jpg';
import image11 from '../../assets/VAKLVE/disc-type-check-valve-500x5001.jpg';
import image12 from '../../assets/VAKLVE/Wafer-check-valve11.jpg';
import image13 from '../../assets/VAKLVE/Three-Piece-Ball-Valve-Screwed_2.png';
import image14 from '../../assets/VAKLVE/Three-Piece-Ball-Valve.png';
import image15 from '../../assets/VAKLVE/Swing-Check-Valve-edit.png';

const MainCarousel = () => {
    const settings = {
        infinite: true,
        arrows: false,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        cssEase: "linear",
    };

    const [slidesToShow, setSlidesToShow] = useState(6); // Default to 6 slides

    useEffect(() => {
        const updateSlidesToShow = () => {
            if (window.innerWidth <= 576) {
                setSlidesToShow(2);
            }
            else if (window.innerWidth <= 768) {
                setSlidesToShow(4);
            }
            else if (window.innerWidth <= 1200) {
                setSlidesToShow(5);
            }

            else {
                setSlidesToShow(6);
            }
        };

        updateSlidesToShow();

        window.addEventListener('resize', updateSlidesToShow);

        return () => {
            window.removeEventListener('resize', updateSlidesToShow);
        };
    }, []);

    settings.slidesToShow = slidesToShow
    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15]
    const slides = [];

    for (let i = 0; i < images.length; i++) {
        slides.push(
            <div key={i}>
                <img src={images[i]} alt='' style={{ width: '100%', objectFit: 'cover' }} />
                {/* <div className="label">Image {i + 1}</div> */}
            </div>
        );
    }
    return (
        <Slider {...settings}>
            {slides}
        </Slider>

    );
}
export default MainCarousel;