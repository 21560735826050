import './textarea.styles.scss'

const Textarea = (props) => {
    const { LabelSymbol, name, value, onChange, className } = props;
    var { placeholder } = props;
    if (!placeholder) placeholder = 'Enter Your Message'

    return (
        <div className={`input-group ${className}`}>
            {LabelSymbol ? <label for={name} className="input-group-text"><LabelSymbol /></label> : ''}
            <textarea className='form-control' value={value} id={name} name={name} placeholder={placeholder} onChange={onChange} />
        </div>
    );

}
export default Textarea;