import './quantity.styles.scss';

const Quantity = (props) => {
    const { onInputChange, onUnitChange } = props;

    return (
        <div className="quantity">
            <input className="p-0 ps-2" placeholder="1" type='number' min='1' onChange={onInputChange} />
            <select class=" p-0 py-1" aria-label="Small select example" onChange={onUnitChange}>
                <option selected value='units'>units</option>
                <option value="meters">mts</option>
                {/*  <option value="2">Two</option> */}
                {/* <option value="3">Threedasdsadasdasdsa</option> */}
            </select>
        </div>
    );
}

export default Quantity;