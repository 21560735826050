import { useState, useEffect } from 'react';
import './contact.styles.scss';
import axios from 'axios';
import { ReactComponent as Email } from "../../assets/envelope-at-fill.svg";
import { ReactComponent as Name } from "../../assets/person-circle.svg";
import { ReactComponent as Phone } from "../../assets/telephone-fill.svg";
import { ReactComponent as Message } from "../../assets/chat-square-text-fill.svg";
import { ReactComponent as Location } from "../../assets/pin-map-fill.svg";
import { ReactComponent as Calendar } from "../../assets/calendar-fill.svg";
import InputBox from "../../component/Input-box/input-box.component";
import Textarea from "../../component/textarea/textarea.component.jsx";
import Button from "../../component/button/button.component";
import Map from "../../component/map/map.component";
import ContactLink from "../../component/contact-link/contact-link.component";
import Alert from '../../component/alert/alert.component';
import { useContext } from 'react';
import { ToastContext } from '../../context/toast.context';

const defaultFormFields = {
    name: '',
    phone: '',
    email: '',
    message: ''
}

const focus = { name: false, phone: false, email: false }

const Contact = () => {

    const { addToastToArray } = useContext(ToastContext);

    const [formFields, setFormFields] = useState(defaultFormFields)
    const [nameAlert, setNameAlert] = useState('');
    const [emailAlert, setEmailAlert] = useState('');
    const [phoneAlert, setPhoneAlert] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { name, email, phone, message } = formFields;


    const validateName = () => {
        const validName = (name) => {
            const regex = /^[A-Za-z\s]+$/;
            return regex.test(name);
        }

        if (name.length < 1 && focus.name) setNameAlert('Please Enter Your Name')
        else if (!validName(name) && focus.name) setNameAlert('Please Enter Valid Name')
        else setNameAlert('');
    }

    const validateEmail = () => {
        const validEmail = (email) => {
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return regex.test(email);
        }

        if (email.length < 1 && focus.email) setEmailAlert('Please Enter Email')
        else if (!validEmail(email) && focus.email) setEmailAlert('Please Enter Valid Email')
        else setEmailAlert('');
    }

    const validatePhone = () => {

        const validPhone = (phone) => {
            const numericRegex = /^[0-9]+$/;
            return numericRegex.test(phone);
        }

        if (phone.length < 1 && focus.phone) setPhoneAlert('Please Enter Phone Number')
        else if (!validPhone(phone) && focus.phone) setPhoneAlert('Please Enter Valid Number')
        else if (phone.length === 11 && phone[0] === '0') setPhoneAlert('')
        else if (phone.length !== 10 && focus.phone) setPhoneAlert('Enter 10 digit Number')
        else setPhoneAlert('')
    }

    useEffect(validatePhone, [phone])
    useEffect(validateEmail, [email])
    useEffect(validateName, [name]);


    const handleChange = (e) => {
        const { id, value } = e.target;
        focus[id] = true;
        setFormFields({ ...formFields, [id]: value });
    }

    const checkOnFocus = () => {
        for (const id in focus) {
            if (focus[id] === false) {
                return true;
            }
        }
        return false;
    }

    const turnOnFocus = () => {
        for (const id in focus) focus[id] = true;
        validateEmail();
        validatePhone();
        validateName();
    }

    const resetForm = () => {
        setFormFields(defaultFormFields);
        for (const id in focus) focus[id] = false;
        setEmailAlert('');
        setPhoneAlert('');
        setNameAlert('');
    }

    const sendEmail = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (phoneAlert.length > 0 || nameAlert.length > 0 || emailAlert.length > 0 || checkOnFocus()) {
            turnOnFocus();
        }
        else {
            const emailContent = {
                name: name,
                email: email,
                phone: phone,
                message: message
            }
            try {
                const response = await axios.post('/.netlify/functions/send-email', emailContent);

                const { emailError } = response.data;
                if (emailError === false) {
                    resetForm();
                    addToastToArray({ type: 'success', text: 'Message sent successfully' });
                } else {
                    const { error } = response.data;
                    console.error('error sending email in serverless function', error);
                    addToastToArray({ type: 'danger', text: 'Failed to send message' });
                }
            } catch (error) {
                console.error('error sending email in contact component:', error);
                addToastToArray({ type: 'danger', text: 'Failed to send message' });
            }
        }
        setIsLoading(false);
        return;

    }


    return (
        <div className="row m-0  mt-3 mx-3">
            <div className="col-md-4 mt-2 order-md-2 px-0 px-sm-2">
                <div className="mb-3">
                    <p className="text-center cdh mb-3">Our contact details</p>
                    <div className="cdl d-flex flex-column ms-3">
                        <ContactLink type='tel' content='+91-9030744264' LabelSymbol={Phone} />
                        <ContactLink type='mailto' content='vec.2011@gmail.com' LabelSymbol={Email} />
                    </div>

                </div>
                <form>
                    <p className="text-center cdh mb-3">Send us a message</p>
                    <InputBox LabelSymbol={Name} type='text' name='name' placeholder="Enter Your Name" onChange={handleChange} value={name} className='mb-3' />
                    {nameAlert ? (<Alert alertMessage={nameAlert} />) : ('')}
                    <InputBox LabelSymbol={Email} type='email' name='email' placeholder="Enter Your Email" onChange={handleChange} value={email} className='mb-3' />
                    {emailAlert ? (<Alert alertMessage={emailAlert} />) : ('')}
                    <InputBox LabelSymbol={Phone} type='tel' name='phone' placeholder="Enter Your Phone" onChange={handleChange} value={phone} className='mb-3' />
                    {phoneAlert ? (<Alert alertMessage={phoneAlert} />) : ('')}
                    <Textarea name='message' LabelSymbol={Message} onChange={handleChange} value={message} className='mb-4' />
                    <Button text='Send' className onClick={sendEmail} isLoading={isLoading} />
                </form>
            </div>

            <div className="col mt-2 order-md-1">
                <div className="d-flex flex-column">
                    <p className="text-center cdh mb-3">Our location</p>
                    <Map />
                </div>
                <div className="mt-3 address">
                    <p className="text-center cdh mb-3">Our Office Address</p>
                    <p className="d-flex align-items-center justify-content-center"><Location style={{ flexShrink: 0 }} />
                        <span className="ms-2">5-2-261,262, Hyder Basti, Bolaram Nagar, Rani Gunj, Secunderabad, Telangana 500003.</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-center"><Calendar style={{ flexShrink: 0 }} />
                        <span className="ms-2">Monday - Saturday, 10:00 AM - 7:00 PM</span>
                    </p>

                </div>
            </div>


        </div>

    );
}

export default Contact;